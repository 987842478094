import React from 'react';
import styled from 'styled-components';
import {
  GridFlex,
  GridBox,
  Container,
} from '@components';

const StyledContainer = styled(Container)`
  text-align: center;
`;

const NotFoundPage = () => (
  <StyledContainer>
    <GridFlex
      justifyContent="center"
      p={[2, 3, 4, 5]}
    >
      <GridBox>
        <h1>404: Nie znaleziono</h1>
        <h2>
          Strona o podanym adresie nie istnieje
        </h2>
      </GridBox>
    </GridFlex>
  </StyledContainer>
);

export default NotFoundPage;
